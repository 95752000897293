@tailwind base;
@tailwind components;
@tailwind utilities;

@import './Satoshi.css';

@layer base {
    body {
        @apply relative text-base font-satoshi text-body bg-body1 z-1;
    }
}

@layer components {
}

@layer utilities {
    /* Chrome, Safari and Opera */
    .no-scrollbar::-webkit-scrollbar {
        display: none;
    }

    .no-scrollbar {
        -ms-overflow-style: none;
        /* IE and Edge */
        scrollbar-width: none;
        /* Firefox */
    }

    .chat-height {
        @apply h-[calc(100vh_-_8.125rem)] lg:h-[calc(100vh_-_5.625rem)];
    }

    .inbox-height {
        @apply h-[calc(100vh_-_8.125rem)] lg:h-[calc(100vh_-_5.625rem)];
    }
}

/* third-party libraries CSS */

*::-webkit-scrollbar {
    @apply cursor-pointer w-1.5 h-1.5 lg:w-2.5 lg:h-2.5;
}

*::-webkit-scrollbar-track {
    @apply bg-stroke dark:bg-strokedark;
}

*::-webkit-scrollbar-thumb {
    @apply rounded-full bg-graydark/50 dark:bg-bodydark/50 hover:bg-graydark dark:hover:bg-bodydark;
}

.apexcharts-legend-text {
    @apply !text-body dark:!text-bodydark;
}

.apexcharts-text {
    @apply !fill-body dark:!fill-bodydark;
}

.apexcharts-xcrosshairs {
    @apply !fill-stroke dark:!fill-strokedark;
}

.apexcharts-gridline {
    @apply !stroke-stroke dark:!stroke-strokedark;
}

.apexcharts-series.apexcharts-pie-series path {
    @apply dark:!stroke-transparent;
}

.apexcharts-legend-series {
    @apply !inline-flex gap-1.5;
}

.apexcharts-tooltip.apexcharts-theme-light {
    @apply dark:!bg-boxdark dark:!border-strokedark;
}

.apexcharts-tooltip.apexcharts-theme-light .apexcharts-tooltip-title {
    @apply dark:!bg-boxdark3 dark:!border-strokedark;
}

.apexcharts-xaxistooltip,
.apexcharts-yaxistooltip {
    @apply dark:!bg-boxdark3 dark:!border-boxdark3 dark:!text-bodydark1;
}

.apexcharts-xaxistooltip-bottom:after {
    @apply dark:!border-b-boxdark3;
}

.apexcharts-xaxistooltip-bottom:before {
    @apply dark:!border-b-boxdark3;
}

.custom-input-date::-webkit-calendar-picker-indicator {
    background-position: center;
    background-repeat: no-repeat;
    background-size: 20px;
}

[x-cloak] {
    display: none !important;
}
button:focus {
    box-shadow: none !important;
}
